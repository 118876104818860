import React from 'react'
import {Row, Col, Carousel,Image} from 'react-bootstrap'
import sliderImages from '../sliderImage'
import SliderContent from '../SliderContent'
import StarIcon from 'mdi-react/StarIcon';
import crown from '../../assets/crown.png'
import imgCandidate from "../../assets/img-avatar.png";
const ResultSlider = ({results}) => {
    return (
        <>
           <section>
                <Carousel>
                {results && results.map((res, index) => {
                    const resultsData=res.results.sort((a, b) => a.voteCount > b.voteCount ? -1 : 1)
                    let lead=0;
                    if(resultsData && resultsData.length>1){
                      lead=resultsData[0].voteCount-resultsData[1].voteCount;
                    }
                    else if(resultsData && resultsData.length>0){
                        lead=resultsData[0].voteCount;
                      }
                    else{
                        lead=0;
                    }

                  return (
                    <Carousel.Item>
                    <div className="president-hds">
                        <span>
                        <StarIcon size={16} color='#a5abb8' className="mr-3" />
                        {res.association_position && res.association_position?.name !== undefined ? res.association_position.name:""}
                        <StarIcon size={16} color='#a5abb8' className="ml-3" />
                        </span>
                    </div>
                    <Row className="justify-content-md-center">
                        <Col sm="12" md="12" lg="6" className="grey-height-over scrollbar-primary">
                            <div>
                            {res.results && res.results.filter(x => x.isWinner ===true).length>1 && res.results.filter(x => x.isWinner ===true).map((candidate, index) => {
                                return (
                                <div className="bg-gray-slid">
                                    <div className="d-flex w-100 justify-content-between slider-left">
                                        <div className="blue-over-img">
                                         <Image src={candidate.nomination && candidate.nomination.profileImage?candidate.nomination.profileImage:imgCandidate}  onError={(e) => { e.target.onerror = null; e.target.src = imgCandidate }} alt="" />
                                            <div className="crown-above">
                                            <Image src={crown} alt=" " />
                                            </div>
                                        </div>
                                        <div className="w-100 left-spc">
                                            <h4>{candidate.nomination && candidate.nomination.name?candidate.nomination.name:""}</h4>
                                            <h6>{"PF#: "+(candidate.nomination && candidate.nomination.pfNumber?candidate.nomination.pfNumber:"")}</h6>
                                            <h6>{candidate.nomination && candidate.nomination.primaryContactNumber?candidate.nomination.primaryContactNumber:""}</h6>
        
                                        </div>
                                        <div className="total-count">
                                            <span>Total Votes</span>
                                            <div>{candidate.voteCount?candidate.voteCount:0}</div>
                                        </div>
                                    </div>
                                </div>
                                 )})
                                } 
                                {res.results && res.results.filter(x => x.isWinner ===true).length===1 && res.results.filter(x => x.isWinner ===true).map((candidate, index) => {
                                return (
                                    <div className="bg-blue-slid">
                                        <div className="d-flex w-100 justify-content-between slider-left">
                                            <div className="blue-over-img">
                                            <Image src={candidate.nomination && candidate.nomination.profileImage?candidate.nomination.profileImage:imgCandidate}   onError={(e) => { e.target.onerror = null; e.target.src = imgCandidate }} alt=""/>
                                                <div className="crown-above">
                                                    {sliderImages.crownImg}
                                                </div>
                                            </div>
                                            <div className="w-100 left-spc">
                                                <h4>{candidate.nomination && candidate.nomination.name?candidate.nomination.name:""}</h4>
                                                <h6>{"PF#: "+(candidate.nomination && candidate.nomination.pfNumber?candidate.nomination.pfNumber:"")}</h6>
                                                <h6>{candidate.nomination && candidate.nomination.primaryContactNumber?candidate.nomination.primaryContactNumber:""}</h6>
                                                <div className="d-flex w-100 justify-content-lg-start justify-content-around">
                                                    <div className="total-count">
                                                        <span>Total Votes</span>
                                                        <div>{candidate.voteCount?candidate.voteCount:0}</div>
                                                    </div>
                                                    <div className="total-count">
                                                        <span>Lead</span>
                                                        <div>{lead}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                 )})
                                }
                            </div>
                        </Col>
                        <Col sm="12" md="12" lg="6" className="slider-right-list">
                            <div className="other-list"><span>Others</span></div>
                            {res.results && res.results.filter(x => x.isWinner ===false).map((candidate, index) => {
                                return (
                                <SliderContent candidate={candidate} />
                                )})
                            }
                        </Col>
                    </Row>
                    </Carousel.Item>
                  )})
                  } 
                  {
                    results && results.length===0 &&
                    <div className="alignCenter">
                    Results are not published yet.
                    </div>
                  }
                </Carousel>
           </section>
        </>
    )
}
export default ResultSlider
