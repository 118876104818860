import React, { useContext } from "react";
import routes from "./constants/routes.json";
import { useLocation, Route, Redirect } from "react-router-dom";
import { store } from "./contexts/store";

const PrivateRoute = (props) => {
  const globalState = useContext(store);
  const token = globalState.state.usertoken;
  const location = useLocation();
  return token!=="" ? (
    <Route {...props} />
  ) : (
    <Redirect to={routes.LOGIN} />
  );
};

export default PrivateRoute;
