import Icons from "../components/Icons";
import Routes from "./routes.json";

const HomeData = [
  // {
  //   id: "homeCard-1",
  //   item: "Notifications",
  //   location: Routes.NOTIFICATIONS,
  //   icon: Icons.notification,
  // },
  // {
  //   id: "homeCard-2",
  //   item: "Nominations",
  //   location: Routes.NOMINATIONS,
  //   icon: Icons.nominations,
  // },
  {
    id: "homeCard-3",
    item: "Candidate List",
    location: Routes.CANDIDATELIST,
    icon: Icons.candidateList,
  },
  {
    id: "homeCard-4",
    item: "Voting",
    location: Routes.VOTING,
    icon: Icons.voting,
  },
  {
    id: "homeCard-5",
    item: "Results",
    location: Routes.RESULT,
    icon: Icons.results,
  },
  {
    id: "homeCard-6",
    item: "EC 2023 - 25",
    location: Routes.NEWASSOCIATION,
    icon: Icons.new_association,
  },
];

export default HomeData;
