import React, { createContext, useState,useEffect,useContext } from "react";
import { store } from "../../contexts/store";
import API from "../../Api/api";

export const ResultContext = createContext();
export const ResultContextProvider = ({ children }) => {
  const globalState = useContext(store);
  const [results, setResult] = useState([]);

  const resultData2 = [
    {
      position:"President",
      positionId: "111",
      candidate_list:[{name:"Sophia Brown",pfnumber:"1234512345",phone:"9876510000/9876510001",votes:12000,lead:1000,iswinner:true},
      {name:"Rachel Newton",pfnumber:"1234512345",phone:"9876510000/9876510001",votes:9000,iswinner:false},
      {name:"Brown Sophia",pfnumber:"1234512345",phone:"9876510000/9876510001",votes:12000,iswinner:false}]
    },
    {
        position:"Vice President",
        positionId: "222",
        candidate_list:[{name:"Marcus Stevens",pfnumber:"1234512345",phone:"9876510000/9876510001",votes:11800,iswinner:true},
        {name:"Theresa Becker",pfnumber:"1234512345",phone:"9876510000/9876510001",votes:8000,iswinner:true},
        {name:"Marcus Stevens",pfnumber:"1234512345",phone:"9876510000/9876510001",votes:11800,iswinner:true},
        {name:"Rachel Newton",pfnumber:"1234512345",phone:"9876510000/9876510001",votes:9000,iswinner:false},
        {name:"Brown Sophia",pfnumber:"1234512345",phone:"9876510000/9876510001",votes:12000,iswinner:false},
    ]
    }
  ];

  const getResultApi = async () => {
    API.getResults(globalState.state.user._id)
      .then(async (response) => {
        const resultData = response.data.election_results.filter(x=>(x.isPublished && x.isPublished===true));
        setResult(resultData)
      })
      .catch((e) => {
        console.log("error of Nominations Api ", e);
      });
  };

  const providerValue = {
    results,
    setResult
  };

  useEffect(async () => {
    getResultApi();
  }, []);

  return (
    <ResultContext.Provider value={providerValue}>
      {children}
    </ResultContext.Provider>
  );
};

