import React, { createContext, useState,useEffect, useContext } from "react";
import DummyData from "../../constants/dummyData/DummyList";
import API from "../../Api/api";
import {store} from "../../contexts/store";


export const CandidateListContext = createContext();

export const CandidateListContextProvider = ({ children }) => {
  const globalState = useContext(store);
  const [positionList, setPositionList] = useState([]);
  const [candidates, setCandidates] = useState([]);
  const [defaultPosition, setDefaultPosition] = useState(null);
  const [positionName, setPositionName] = useState("");

  const getAssociationPositionsApi = async () => {
    let positions=[];
    API.getAssociationPositions()
      .then(async (response) => {
        positions=response.data.association_list
        setPositionList(positions);
        setDefaultPosition(positions.length>0?positions[0]._id:"");
        setPositionName(positions.length>0?positions[0].name:"");
      })
      .catch((e) => {
        console.log("error of position list", e);
      });
  };
  const onTabSelect=(id,name,count)=>{
    setDefaultPosition(id);
    setPositionName(name);
    window.scrollTo(0, 0);
  }

  const getNominationsApi = async () => {
    API.getAllNominations()
      .then(async (response) => {
        // setCandidates(response.data.nomination_list.filter(x=>x.status==="Approved"));
        setCandidates(shuffleArray(response.data.nomination_list.filter(x=>x.status==="Approved")));
      })
      .catch((e) => {
        console.log("error of Nominations Api ", e);
      });
  };

  const shuffleArray=(candidateList)=> {
    let i = candidateList.length - 1;
    for (; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      const temp = candidateList[i];
      candidateList[i] = candidateList[j];
      candidateList[j] = temp;
      candidateList[i].slno=i;
      candidateList[j].slno=j;
    }
    return candidateList;
  }

  const providerValue = {
    positionList,
    setPositionList,
    candidates,
    setCandidates,
    onTabSelect,
    defaultPosition, 
    setDefaultPosition,
    positionName, 
    setPositionName
  };

  useEffect(async () => {
    getNominationsApi();
    getAssociationPositionsApi();
  }, []);

  return (
    <CandidateListContext.Provider value={providerValue}>
      {children}
    </CandidateListContext.Provider>
  );
};

