import React, { memo } from "react";
import { Col, Row, Card, Form } from "react-bootstrap";
import Icons from "../components/Icons";
import moment from 'moment';

const InfoCard = ({ data,location,setLocation ,mandatoryValid,setMandatoryValid,setSubmitDisable}) => {
  return (
    <Row>
      <Col lg={12}>
        <Card className="mt-3">
          <Card.Body>
            {data && (
              <Row>
                <Col sm={12} md={6} lg={6}>
                  <div className="d-flex w-100 theme-styled-group mb-3">
                    <div className="d-flex justify-content-center align-items-start iconMinWidth">
                      {Icons.name}
                    </div>
                    <div className="w-100 ml-3">
                      <Form.Group controlId="formFile" className="">
                        <Form.Label>Name</Form.Label>
                        <Form.Control
                          type="text"
                          value={data?.candidateName}
                          disabled
                        />
                      </Form.Group>
                    </div>
                  </div>
                  <div className="d-flex theme-styled-group mb-3">
                    <div className="d-flex justify-content-center align-items-start iconMinWidth">
                      {Icons.place}
                    </div>
                    <div className="w-100 ml-3">
                      <Form.Group controlId="formFile" className="">
                        <Form.Label>Place</Form.Label>
                        <input type="text" className={"borderNone"} onFocus={()=>(setSubmitDisable(false))} onChange={(e)=>setLocation(e.target.value)} value={location}/>
                      </Form.Group>
                    </div>
                  </div>
                  <div className="d-flex theme-styled-group mb-3">
                    <div className="d-flex justify-content-center align-items-start iconMinWidth">
                      {Icons.date}
                    </div>
                    <div className="w-100 ml-3">
                      <Form.Group controlId="formFile" className="">
                        <Form.Label className="nomini-date">
                          <div>Date</div>
                          <label style={{color:'#484848'}}>
                          {moment(data.date).format("DD/MM/YYYY")}
                          </label>
                        </Form.Label>
                        {/* <Form.Control type="text"   /> */}
                      </Form.Group>
                    </div>
                  </div>
                </Col>
                <Col sm={12} md={6} lg={6}>
                  <div className="d-flex theme-styled-group mb-3">
                    <div className="d-flex justify-content-center align-items-start iconMinWidth2">
                      {Icons.pf_number}
                    </div>
                    <div className="w-100 ml-3">
                      <Form.Group controlId="formFile" className="">
                        <Form.Label>PF Number</Form.Label>
                        <Form.Control
                          type="text"
                          value={data.pfNumber}
                          disabled
                        />
                      </Form.Group>
                    </div>
                  </div>
                  <div className="d-flex theme-styled-group mb-3">
                    <div className="d-flex justify-content-center align-items-start iconMinWidth2">
                      {Icons.address}
                    </div>
                    <div className="w-100 ml-3">
                      <Form.Group controlId="formFile" className="">
                        <Form.Label>Address</Form.Label>
                        <Form.Control
                          as="textarea"
                          value={data.address}
                          rows={4}
                          disabled
                        />
                      </Form.Group>
                    </div>
                  </div>
                </Col>
              </Row>
            )}
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

export default memo(InfoCard);
