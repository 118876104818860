import React, { createContext, useState,useEffect,useContext } from "react";
import { store } from "../../contexts/store";
import API from "../../Api/api";

export const AssociationContext = createContext();
export const AssociationContextProvider = ({ children }) => {
  const globalState = useContext(store);
  const [association, setAssociation] = useState([]);

  const associationData = [
    {
      position:"President",
      positionId: "l-1",
      name:"Patrick Brown",
      id:"111"
    },
    {
        position:"Vice President",
        positionId: "l-2",
        name:"Ryan Newton",
        id:"222"
    },
    {
      positionId: "l-3",
      position: "General Secretary",
      name:"Sophia Brown",
      id:"333"
    },
    {
      positionId: "l-4",
      position: "Deputy General Secretary",
      name:"Ryan Newton",
      id:"444"
    },
    {
      positionId: "l-5",
      position: "Executive Members(Womens)",
      name:"Sophia Brown",
      id:"555"
    },
    {
      positionId: "l-6",
      position: "Treasurer",
      name:"Chris Sophia",
      id:"666"
    },
    {
      positionId: "l-7",
      position: "Executive Committe Members",
      name:"Rachel Newton",
      id:"777"
    }
  ];

  const getResultApi = async () => {
    API.getResults(globalState.state.user._id)
      .then(async (response) => {
        const resultData = response.data.election_results.filter(x=>(x.isPublished && x.isPublished===true));
        setAssociation(resultData)
      })
      .catch((e) => {
        console.log("error of Nominations Api ", e);
      });
  };

  const providerValue = {
    association,
    setAssociation
  };

  useEffect(async () => {
      // setAssociation(associationData);
      getResultApi();
  }, []);

  return (
    <AssociationContext.Provider value={providerValue}>
      {children}
    </AssociationContext.Provider>
  );
};

