import React from 'react'
import {Row, Col, Image} from 'react-bootstrap'
import imgCandidate from "../assets/img-avatar.png";

function SliderContent({candidate}) {
    return (

        <>
                <div className="slider-sub-names">
                    <Row className="justify-content-between">
                        <Col xs="6" sm="6" md="8" lg="8">
                        <div className="d-flex w-100 justify-content-between">
                                <div className="mr-3">
                                <Image src={candidate.nomination && candidate.nomination.profileImage?candidate.nomination.profileImage:imgCandidate}   onError={(e) => { e.target.onerror = null; e.target.src = imgCandidate }} alt="" />
                                </div>
                                <div className="w-100">
                                    <h4>{candidate.nomination && candidate.nomination.name?candidate.nomination.name:""}</h4>
                                    <h6>{"PF#: "+(candidate.nomination && candidate.nomination.pfNumber?candidate.nomination.pfNumber:"")}</h6>
                                    <h6>{candidate.nomination && candidate.nomination.primaryContactNumber?candidate.nomination.primaryContactNumber:""}</h6>
                                </div>
                        </div>
                        </Col>
                        <Col xs="6" sm="6" md="4" lg="4">
                            <h5 className="align-items-center">{candidate.voteCount?candidate.voteCount:0}</h5>
                        </Col>
                    </Row>
                    </div>  
        </>
    )
}

export default SliderContent
