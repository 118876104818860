import Requests from "../constants/requests.json";
import axios from "axios";
const URL = process.env.REACT_APP_USER_API_URL;
const URL2 = process.env.REACT_APP_API_URL;
const AuthKey = process.env.REACT_APP_AUTH_KEY;

const Api = {
  userLogin({ phoneNumber }) {
    return axios.post(URL + Requests.LOGIN, {
      mobile: phoneNumber,
    });
  },
  verifyOtp({ otp, phoneNumber }) {
    return axios.post(URL + Requests.OTP, {
      mobile: phoneNumber,
      otp: otp,
    });
  },
  getUser(userTocken) {
    return axios.get(URL + Requests.USER, {
      headers: {
        "x-auth-token": userTocken,
      },
    });
  },
  getSpecialToken(phoneNumber, purpose_id, userToken) {
    return axios.put(
      URL + Requests.SPL_TOKEN,
      {
        mobile: `${phoneNumber}`,
        purpose_id: purpose_id,
      },
      {
        headers: {
          "x-auth-token": userToken,
        },
      }
    );
  },
  getElectionToken(phoneNumber, userToken) {
    return axios.post(
      URL + Requests.ELECTIONTOKEN,
      {
        mobile: `${phoneNumber}`,
      },
      {
        headers: {
          "x-auth-token": userToken,
        },
      }
    );
  },
  saveNomination(
    positionId,
    candidateName,
    pfNumber,
    branch,
    address,
    gender,
    memberId,
    splTocken,
    phoneNumber,
    positionName,
    email,
    profileImage,
    membershipStatus,
    designation
  ) {
    return axios.post(
      URL2 + Requests.NOMINATIONS,
      {
        positionId: positionId,
        name: candidateName,
        pfNumber: pfNumber,
        branch: branch,
        address: address,
        gender: gender,
        primaryContactNumber: phoneNumber,
        email: email,
        profileImage: profileImage,
        membershipStatus: membershipStatus,
        position: positionName,
        designation: designation,
      },
      {
        headers: {
          memberId: memberId,
          sessionToken: splTocken,
          mobile: phoneNumber,
          "Auth-Key": AuthKey,
        },
      }
    );
  },
  getAssociationPositions() {
    return axios.get(URL2 + Requests.POSITIONS + "?page=1&size=10000", {
      headers: {
        "Auth-Key": AuthKey,
      },
    });
  },
  saveVotes(userId, mode, votes, splTocken, phoneNumber) {
    return axios.post(
      URL2 + Requests.VOTE,
      {
        userId: userId,
        mode: mode,
        votes: votes,
      },
      {
        headers: {
          sessionToken: splTocken,
          mobile: phoneNumber,
          "Auth-Key": AuthKey,
        },
      }
    );
  },
  getVotes(userId) {
    return axios.get(
      URL2 + Requests.VOTE + "?userId=" + userId + "&page=1&size=10000",
      {
        headers: {
          "Auth-Key": AuthKey,
        },
      }
    );
  },
  getNominations(memberId) {
    return axios.get(
      URL2 +
        Requests.NOMINATIONS +
        "?memberId=" +
        memberId +
        "&page=1&size=10000",
      {
        headers: {
          "Auth-Key": AuthKey,
        },
      }
    );
  },
  getNotifications(token) {
    return axios.get(URL + Requests.NOTIFICATIONS, {
      headers: {
        "election-token": token,
      },
    });
  },
  getAllNominations() {
    return axios.get(
      URL2 + Requests.NOMINATIONS + "?status=Approved&page=1&size=10000",
      {
        headers: {
          "Auth-Key": AuthKey,
        },
      }
    );
  },
  getSettings() {
    return axios.get(URL2 + Requests.SETTINGS, {
      headers: {
        "Auth-Key": AuthKey,
      },
    });
  },
  withdrawNomination(nominationID, specialTocken, phoneNumber, memberId) {
    return axios.patch(
      URL2 + Requests.NOMINATIONWITHDRAW + "/" + nominationID,
      {
        remarks: "No reasons given",
        member_id: memberId,
      },
      {
        headers: {
          sessionToken: specialTocken,
          mobile: phoneNumber,
          "Auth-Key": AuthKey,
        },
      }
    );
  },
  getResults(userToken) {
    return axios.get(URL2 + `${Requests.RESULT}`, {
      headers: {
        "Auth-Key": AuthKey,
        Authorization: `Bearer ${userToken}`,
      },
    });
  },
  voterRegistration(userToken) {
    return axios.put(
      URL + Requests.VOTER_REGISTRATION,
      {
        // registration_status: false,
      },
      {
        headers: {
          "x-auth-token": userToken,
        },
      }
    );
  },
};

export default Api;

//TODO : ADD TRY CATCH FOR API CALLS
