import React, { useContext } from "react";
import { Col, Row} from "react-bootstrap";
import moment from 'moment';
import { store } from "../../contexts/store";

const NominationSubList = ({nomination,onWidthdarwClick,allowWithdraw}) => {
    const globalState = useContext(store);
    return (
        <div>
               <div className="list-nomini-sub">
                  <Row  className="justify-content-md-center">
                  <Col xs sm={!globalState.state.submitNominations && globalState.state.nominationEnableCount===1?"9":"12"} md={!globalState.state.submitNominations && globalState.state.nominationEnableCount===1?"7":"12"} lg={!globalState.state.submitNominations && globalState.state.nominationEnableCount===1?"7":"12"} xl={!globalState.state.submitNominations && globalState.state.nominationEnableCount===1?"8":"12"}>
                      <h4>{nomination.association?nomination.association.name:""}</h4>
                      <h6 className="nomargin">{"Submission Date : "}<b>{moment(nomination.createdAt).format("MMMM DD,YYYY")}</b></h6>
                      {nomination.rejectionNote&& nomination.rejectionNote!==""&& (allowWithdraw||globalState.state.withdrawNominationsEnableCount===1) && nomination.status==="Rejected" &&
                      <div className="rejectionNote"><span><b>{nomination.rejectionNote&& nomination.rejectionNote!==""?"Rejection Note : ":""}</b>{nomination.rejectionNote&& nomination.rejectionNote!==""?nomination.rejectionNote:""}</span></div>
                      }
                    </Col>
                    {!globalState.state.submitNominations && globalState.state.nominationEnableCount===1 &&
                    <Col xs sm="3" md="5" lg="5" xl="4">
                        <button disabled={allowWithdraw && (nomination.status==="Approved"||nomination.status==="Pending")?false:true} className={(nomination.status==="Approved" && !allowWithdraw)?"Approved-btns":(nomination.status==="Pending" && !allowWithdraw)?"Pending-btns":(nomination.status==="Approved"||nomination.status==="Pending") && allowWithdraw?"withdraw-btns":(nomination.status.toLowerCase()==="withdraw"?"warning-orange":"withdrawed-btns")} type="button" onClick={()=>onWidthdarwClick(nomination._id,nomination.association?nomination.association.name:"")}>{allowWithdraw && (nomination.status==="Approved"||nomination.status==="Pending")?"Withdraw":(nomination.status==="Withdraw"?"Withdrawn":nomination.status)}</button>
                    </Col>
                    }   
                  </Row>
               </div>
        </div>
    )
}

export default NominationSubList