import React from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import NominationContainer from "../components/NominationContainer/NominationContainer";
import { NominationContextProvider } from "../components/NominationContainer/NominationContext";

const NominationsPage = () => {
  return (
    <>
      <Header />
      <NominationContextProvider>
        <NominationContainer />
      </NominationContextProvider>
      <Footer />
    </>
  );
};

export default NominationsPage;
