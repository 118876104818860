import React, { useContext } from "react";
import { VotingContext } from "./VotingContext";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Icons from "../Icons";
import { Form } from "react-bootstrap";
import "./styles.css";

const VotingConfirmPopup = () => {
  const votingModel = useContext(VotingContext);
  const {
    showVotingConfirm,
    setShowVotingConfirm,
    unvotedPositions,
    onConfirmClick,
    onCheckedValueChange,
    setShowVoteColours,
    confirmCheck,
  } = votingModel;
  const [checkBoxMessage, setCheckBoxMessage] = React.useState(false);

  React.useEffect(() => {
    setCheckBoxMessage(true);
  }, [showVotingConfirm]);
  return (
    <Modal className="home-modals" show={showVotingConfirm} onHide={()=>setShowVotingConfirm(false)}>
      <Modal.Header
        className="contnt-center"
        onClick={() => setShowVotingConfirm(false)}
        closeButton
      >
        <Modal.Title>Please check!</Modal.Title>
      </Modal.Header>
      <Modal.Body className="contnt-center">
        {unvotedPositions && unvotedPositions.length > 0 && (
          <div>
            <p>You have not voted for these positions.</p>
            <div className="noted-list-pop">
              <ul>
                {unvotedPositions.map((item, key) => {
                  return (
                    <li key={key}>
                      {Icons.warning}
                      {" " + item.name}
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        )}
        <p>
          <b>You will not be able to vote again if you submit now.</b>
        </p>
        <div className="justify-content-md-center row">
          <div className="col-lg-7 col-md-12 col-sm-12 col">
            <div className="d-flex justify-content-start pt-3 pb-2">
              <Form.Group
                className="mb-3"
                controlId="formBasicCheckbox green-form-chk-agree"
              >
                <Form.Check
                  class="border border-danger"
                  onChange={() => void 0}
                  onClick={() => {
                    onCheckedValueChange();
                    setCheckBoxMessage(confirmCheck);
                  }}
                  type="checkbox"
                  label="I, understand that I will not able to vote
                            again in this election.
                            "
                />
              </Form.Group>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="contnt-center">
        {checkBoxMessage && (
          <p style={{ fontSize: "12px", color: "red" }}>
            You have to agree to the terms by enabling <br/> the check box in this form to continue
          </p>
        )}
        <Button
          disabled={confirmCheck ? false : true}
          className="submit-btn-orange"
          variant="primary"
          onClick={() => {
            onConfirmClick();
          }}
        >
          SUBMIT
        </Button>
        <div>
          <Button
            className="back-btn-orange"
            onClick={() => (
              setShowVotingConfirm(false), setShowVoteColours(true)
            )}
          >
            BACK TO VOTE
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default VotingConfirmPopup;
