import React, { useContext } from 'react';
import { ResultContext } from './ResultContext';
import ResultSlider from './ResultSlider';
import { Container,Col, Row } from "react-bootstrap";

const ResultContainer = () => {
      const ResultModel = useContext(ResultContext);
      const {
        results
      } = ResultModel;
    return (
        <section className="middle-wrap pt-5 pb-5">
            <Container>
                <Row className="justify-content-md-center">
                    <Col xs sm="12" md="12" lg="12">
                        <div className="president-slid">
                        <ResultSlider results={results}/>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}

export default ResultContainer;