import React, { useContext, useEffect, useState } from "react";
import "./LandingGrid.css";
import { Col, Container, Row, Button, Spinner, Modal } from "react-bootstrap";
import { LandingContext } from "./LandingContext";
import constValues from "../../constants/constantValues.json";
import { store } from "../../contexts/store";
import HomeCard from "../HomeCard";
import HomeData from "../../constants/HomeData";

const LandingGrids = () => {
  const LandingModel = useContext(LandingContext);
  const globalState = useContext(store);
  const { dispatch } = globalState;
  const [show, setShow] = useState(false);

  const handleClose = () => {
    LandingModel.setRegistrationPopup(false);
  };
  const handleShow = () => setShow(true);
  const assignValue = (currentItem) => {
    const notificationString = HomeData?.[constValues.FIRST_VALUE_INDEX]?.item;
    if (currentItem === notificationString)
      return LandingModel.notificationCount;
  };

  useEffect(() => {}, [
    globalState.state.registrationStatus,
    LandingModel.registrationPopup,
  ]);
  useEffect(() => {}, [show]);
  return (
    <section className="middle-wrap pt-5 pb-5">
      {/* <Button
        className="ml-4"
        disabled={
          // (!globalState.state.submitNominations &&
          //   globalState.state.registrationStatus) ||
          // (!globalState.state.submitNominations &&
          //   !globalState.state.registrationStatus) ||
          // (globalState.state.submitNominations &&
          //   globalState.state.registrationStatus)
          globalState.state.voting ||
          globalState.state.votingEnableCount ||
          globalState.state.registrationStatus
        }
        onClick={LandingModel.registerUserAsVoter}
      >
        REGISTER AS A VOTER
      </Button> */}
      <Container>
        <Row className="justify-content-md-center blue-land-btns">
          <Col xs sm="12" md="12" lg="8">
            <Row className="grid-row">
              {HomeData &&
                HomeData?.map((data) => {
                  return (
                    <HomeCard
                      key={data?.id}
                      item={data?.item}
                      location={data?.location}
                      icon={data?.icon}
                      value={assignValue(data?.item)}
                      condition={constValues?.NOTIFICATION_VALUE_CONDITION}
                      isEnable={
                        data?.item === "Voting"
                          ? (globalState.state.voting &&
                              globalState.state.registrationStatus) ||
                            (globalState.state.registrationStatus &&
                              globalState.state.votingEnableCount === 1)
                          : data?.item === "Results"
                          ? globalState.state.declareResults ||
                            globalState.state.publishResultEnableCount === 1
                          : data?.item === "EC 2023 - 25"
                          ? globalState.state.newCommittee ||
                            globalState.state.OnboardNewCommitteeEnableCount ===
                              1
                          : data?.item === "Nominations"
                          ? (globalState.state.submitNominations &&
                              globalState.state.user?.isElectionPanel !==
                                "Y") ||
                            (globalState.state.nominationEnableCount === 1 &&
                              globalState.state.user &&
                              globalState.state.user?.isElectionPanel !== "Y")
                          : data?.item === "Candidate List"
                          ? globalState.state.publishCandidateList ||
                            globalState.state
                              .publishCandidateListEnableCount === 1
                          : true
                      }
                    />
                  );
                })}
            </Row>
          </Col>
        </Row>
      </Container>
      {/* <button onClick={handleShow}>test</button> */}
      <Modal show={LandingModel.registrationPopup} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Voter Registration</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>Voter registration completed. Thank you.</p>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </section>
  );
};
export default LandingGrids;

/**
 * Button should be disabled on
 * nomination status is false
 * registration status is true
 *
 * To do that disabled should be true
 * To make disabled true from above comments we have to
 * invert the process
 * if nomination status is true and registration status is false button disable will true
 */
