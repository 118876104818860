import React, { createContext, useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import DummyList from "../../constants/dummyData/DummyList";
import DummyCandidate from "../../constants/dummyData/DummyCandidate";
import API from "../../Api/api";
import { store } from "../../contexts/store";
import routes from "../../constants/routes.json";
import { SPECIAL_TOKEN } from "../../contexts/types";
import Constants from "../../constants/constantValues";

export const NominationContext = createContext();

export const NominationContextProvider = ({ children }) => {
  const history = useHistory();
  const globalState = useContext(store);
  const { dispatch } = globalState;
  const Imageurl = process.env.REACT_APP_IMAGE_SERVER;
  let nominationsData = [];
  let positionsData = [];
  let allPositionsData = [];
  const [positionList, setPositionList] = useState([]);
  const [allPositionList, setAllpositionList] = useState([]);
  const [candidateName, setCandidateName] = useState(DummyCandidate.name);
  const [locationList, setLocationList] = useState(DummyCandidate.place);
  const [place, setPlace] = useState(DummyCandidate.place[0]);
  const [selectedItem, setSelectedItem] = useState("");
  const [date, setDate] = useState(new Date());
  const [pfNumber, setPfNumber] = useState(DummyCandidate.pfNumber);
  const [address, setAddress] = useState(DummyCandidate.address);
  const [candidateConfirmation, setCandidateConfirmation] = useState(false);
  const [memberDeclaration, setMemberDeclaration] = useState(false);
  const [nominations, setNominations] = useState([]);
  const [gender, setGender] = useState("");
  const [branch, setBranch] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [showSuccess, setShowSuccess] = useState(false);
  const [location, setLocation] = useState("");
  const [mandatoryValid, setMandatoryValid] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [submitDisable, setSubmitDisable] = useState(false);
  const [message, setMessage] = useState("");
  const [allowWithdraw, setAllowWithdraw] = useState(
    globalState.state.withdrawNominations
  );
  const [showGenderConfirmation, setShowGenderConfirmation] = useState(false);
  const [confirmationGender, setConfirmationGender] = useState(false);
  const [positionName, setPositionName] = useState("");
  const [withdrawNominationId, setWithdrawNominationId] = useState("");
  const [isConfirm, setIsConfirm] = useState(false);

  const withdrawNominationApi = async (specialTocken, nominationId) => {
    API.withdrawNomination(
      withdrawNominationId,
      specialTocken,
      globalState.state.user.mobile,
      globalState.state.user._id
    )
      .then(async (response) => {
        const data = response.data;
        getNominationsApi();
        setShowPopup(false);
        setMessage("");
        setWithdrawNominationId("");
      })
      .catch((e) => {
        console.log("error of save nomination", e);
      });
  };

  const saveNominationFromApi = async (specialTocken) => {
    API.saveNomination(
      selectedItem,
      candidateName,
      pfNumber,
      branch,
      address,
      showGenderConfirmation ? "Female" : "",
      globalState.state.user._id,
      specialTocken,
      globalState.state.user.mobile,
      positionName,
      globalState.state.user.email,
      globalState.state.user.profile_image
        ? Imageurl + globalState.state.user.profile_image
        : "",
      globalState.state.user.memberplan
        ? globalState.state.user.memberplan.status
        : "",
      globalState.state.user.designation
        ? globalState.state.user.designation.name
        : ""
    )
      .then(async (response) => {
        setShowSuccess(true);
        setShowSuccess(true);
        setConfirmationGender(false);
        setCandidateConfirmation(false);
        setMemberDeclaration(false);
        setLocation("");
        allPositionsData = allPositionList;
        positionsData = positionList;
        setSelectedItem("");
        getNominationsApi();
      })
      .catch((e) => {
        console.log("error of save nomination", e);
      });
  };

  const getSpecialTokenApi = async (type, nominationId) => {
    API.getSpecialToken(phoneNumber, 0, globalState.state.usertoken)
      .then(async (response) => {
        const specialTocken = response.data.token;
        dispatch({ type: SPECIAL_TOKEN, payload: specialTocken });
        if (type === "save") {
          saveNominationFromApi(specialTocken);
        } else {
          withdrawNominationApi(specialTocken, nominationId);
        }
      })
      .catch((e) => {
        console.log("error of special tocken", e);
      });
  };
  const getAssociationPositionsApi = async () => {
    let positions;
    API.getAssociationPositions()
      .then(async (response) => {
        allPositionsData = response.data.association_list;
        setAllpositionList(response.data.association_list);
        if (
          globalState.state.user &&
          globalState.state.user.zone &&
          globalState.state.user.zone.name
        ) {
          positions = response.data.association_list.filter(
            (x) =>
              x.targetNomineeZone !== null &&
              x.targetNomineeZone?.name !== undefined &&
              (x.targetNomineeZone.name.toLowerCase() ===
                globalState.state.user.zone.name.toLowerCase() ||
                x.targetNomineeZone.name.toLowerCase() === "all")
          );
        } else {
          positions = response.data.association_list.filter(
            (x) =>
              x.targetNomineeZone !== null &&
              x.targetNomineeZone?._id !== undefined &&
              x.targetNomineeZone.name.toLowerCase() === "all"
          );
        }
        positionsData = positions;
        setPositionList(positions);
        if (positions && positions.length > 0) {
          setSelectedItem("");
          setPositionName("");
          const genderconfirm =
            positions[0].gender.toLowerCase() === "female" ? true : false;
          setShowGenderConfirmation(genderconfirm);
          filterPositions();
        }
      })
      .catch((e) => {
        console.log("error of position list", e);
      });
  };
  const getNominationsApi = async () => {
    API.getNominations(globalState.state.user._id)
      .then(async (response) => {
        nominationsData = response.data.nomination_list;
        setNominations(response.data.nomination_list);
        setNominationPositionNames();
        filterPositions();
      })
      .catch((e) => {
        console.log("error of Nominations Api ", e);
      });
  };
  const selectedValueChange = (val) => {
    setSelectedItem(val);
    let position = positionList.filter((x) => x._id === val);
    if (position.length > 0) 
    {
      setPositionName(position[0].name);
      if (position[0].gender && position[0].gender.toLowerCase() === "female") {
        setShowGenderConfirmation(true);
      } else {
        setShowGenderConfirmation(false);
      }
    } 
    else 
    {
      setSelectedItem("");
      setPositionName("");
    }
  };

  const SetUserData = () => {
    console.log()
    setCandidateName(globalState.state.user.name);
    setPfNumber(globalState.state.user.pf_number);
    setAddress(
      globalState.state.user.branch
        ? globalState.state.user.branch.code +
            ", " +
            globalState.state.user.branch.type +
            ", " +
            globalState.state.user.branch.description
        : (globalState.state.user.department && globalState.state.user.department.name ? globalState.state.user.department.name: "")
    );
    setBranch(
      globalState.state.user.branch && globalState.state.user.branch
        ? globalState.state.user.branch.name
        : ""
    );
    setGender(
      globalState.state.user.gender ? globalState.state.user.gender : ""
    );
    setPhoneNumber(
      globalState.state.user.mobile ? globalState.state.user.mobile : ""
    );
  };
  const OnSubmitClick = async() => {
    const settingsStatus=await getSettingsApi(Constants.SUBMIT_NOMINATIONS);
    if (settingsStatus) {
      if (candidateConfirmation && memberDeclaration && selectedItem !== 0) {
        getSpecialTokenApi("save", "");
      }
    }
  };

  const OnCancelClick = () => {
    history.push(routes.LANDING);
  };
  const onBacktoHomeClick = () => {
    setShowSuccess(false);
    history.push(routes.LANDING);
  };
  const onClosePopup = () => {
    setShowSuccess(false);
  };

  const onWidthdarwClick = async(nominationId, positionName) => {
    const settingsStatus= await getSettingsApi(Constants.WITHDRAW_NOMINATIONS);
    if (settingsStatus) {
      setIsConfirm(true);
      setShowPopup(true);
      setWithdrawNominationId(nominationId);
      setMessage(
        "Do you want to withdraw your nomination for " + positionName + "?"
      );
    }
  };

  const getSettingsApi = async(Title) => {
    const status= await API.getSettings().then(async (response) => {
      const data = response.data.settings_list.filter(
        (x) => x.title.trim() === Title && x.isEnabled === true
      );
      if (data && data.length > 0) {
        return true;
      } else {
        setShowPopup(true);
        setIsConfirm(false);
        if (Title === Constants.WITHDRAW_NOMINATIONS) {
          setMessage("Nomination withdrawal is already closed");
          return false;
        } else if (Title === Constants.SUBMIT_NOMINATIONS) {
          setMessage("Nomination submission is already closed");
          return false;
        } else {
          return false;
        }
      }
    });
    return status;
  };

  const onConfirmClick = async () => {
    getSpecialTokenApi("widthdraw", "");
  };

  const setNominationPositionNames = () => {
    let nominationlist = [];
    const allpos = allPositionsData;
    nominationsData.map((noms) => {
      const position = allpos.filter((x) => x._id === noms.positionId);
      if (position.length > 0) {
        noms.position = position[0].name;
      }
      nominationlist.push(noms);
    });
    setNominations(nominationlist);
  };

  const filterPositions = () => {
    const pos = positionsData.slice();
    let index = -1;
    if (pos.length > 0) {
      nominationsData.map((noms) => {
        index = pos.findIndex((x) => x._id === noms.positionId);
        if (index >= 0) {
          pos.splice(index, 1);
        }
      });
    }
    setPositionList(pos);
    if (pos.length > 0) {
      setSelectedItem("");
      setPositionName("");
      const genderconfirm =
        pos[0].gender.toLowerCase() === "female" ? true : false;
      setShowGenderConfirmation(genderconfirm);
    } else {
      setSelectedItem("");
      setPositionName("");
    }
  };

  useEffect(async () => {
    getAssociationPositionsApi();
    getNominationsApi();
    SetUserData();
    setAllowWithdraw(globalState.state.withdrawNominations);
  }, []);

  const providerValue = {
    positionList,
    candidateName,
    place,
    date,
    pfNumber,
    address,
    candidateConfirmation,
    memberDeclaration,
    locationList,
    setPositionList,
    setCandidateName,
    setPlace,
    setDate,
    setPfNumber,
    setAddress,
    setCandidateConfirmation,
    setMemberDeclaration,
    setLocationList,
    nominations,
    onWidthdarwClick,
    selectedItem,
    setSelectedItem,
    selectedValueChange,
    saveNominationFromApi,
    OnSubmitClick,
    OnCancelClick,
    onBacktoHomeClick,
    onClosePopup,
    showSuccess,
    location,
    setLocation,
    mandatoryValid,
    setMandatoryValid,
    allowWithdraw,
    showGenderConfirmation,
    confirmationGender,
    setShowGenderConfirmation,
    setConfirmationGender,
    positionName,
    showPopup,
    setShowPopup,
    onConfirmClick,
    message,
    setSubmitDisable,
    submitDisable,
    isConfirm
  };

  return (
    <NominationContext.Provider value={providerValue}>
      {children}
    </NominationContext.Provider>
  );
};

//TODO: API NOMINATION INTEGRATION AND REPLACE THE DUMMY DATA OF POSITION LIST
