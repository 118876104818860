import React, { createContext, useState ,useEffect,useContext} from "react";
import DummyData from "../../constants/dummyData/DummyNotifications";
import API from "../../Api/api";
import {store} from "../../contexts/store";

export const NotificationContext = createContext();

export const NotificationContextProvider = ({ children }) => {
  const globalState = useContext(store);
  const [notifications, setNotifications] = useState([]);

  const getElectionTokenApi = async () => {
    API.getElectionToken(globalState.state.user.mobile,globalState.state.usertoken)
      .then(async (response) => {
        getNotificationsApi(response.data.token);
      })
      .catch((e) => {
        console.log("error of settings", e);
      });
  };

  const getNotificationsApi = async (token) => {
    API.getNotifications(token)
      .then(async (response) => {
        setNotifications(response.data.notifications);
      })
      .catch((e) => {
        console.log("error of notifications", e);
      });
  };

  const providerValue = {
    notifications,
    setNotifications,
  };
  useEffect(async () => {
    getElectionTokenApi();
}, []);

  return (
    <NotificationContext.Provider value={providerValue}>
      {children}
    </NotificationContext.Provider>
  );
};

//TODO: API NOTIFICATION DATA INTEGRATION AND REPLACE THE DUMMY NOTIFICATION
