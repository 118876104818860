import React from 'react'
import Footer from '../components/Footer'
import Header from '../components/Header'
import "./Voting.css";
import VotingContainer from "../components/Voting/VotingContainer";
import { VotingContextProvider } from "../components/Voting/VotingContext";

const Voting = () => {
    return (
        <>
            <Header />
                <VotingContextProvider>
                    <VotingContainer />
                </VotingContextProvider>
            <Footer />
        </>
    )
}

export default Voting
