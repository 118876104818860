import React, { createContext, useState, useEffect, useContext } from "react";
import DummyData from "../../constants/dummyData/DummyNotifications";
import API from "../../Api/api";
import Constants from "../../constants/constantValues";
import { store } from "../../contexts/store";
import {
  SUBMIT_NOMINATIONS,
  WITHDRAW_NOMINATIONS,
  VOTING,
  DECLARE_RESULT,
  ELECTION_SIGNOFF,
  LOCK_RESULTS,
  ONBOARD_NEW_COMMITTEE,
  PUBLISH_RESULT,
  PUBLISH_CANDIDATE_LIST,
  SUBMIT_NOMINATIONS_ENCOUNT,
  VOTING_ENCOUNT,
  PUBLISH_CANDIDATE_LIST_ENCOUNT,
  ONBOARD_NEW_COMMITTEE_ENCOUNT,
  DECLARE_RESULT_ENCOUNT,
  WITHDRAW_NOMINATIONS_ENCOUNT,
  USER_DATA,
  REGISTRATION_STATUS,
} from "../../contexts/types";

export const LandingContext = createContext();

export const LandingContextProvider = ({ children }) => {
  let myVar = null;
  const globalState = useContext(store);
  const { dispatch } = globalState;
  const [notificationCount, setNotificationCount] = useState(0);
  const [registrationStatus, setRegistrationStatus] = useState(false);
  const [registration, setRegistration] = useState(true);
  const [spinnerStatus, setSpinnerStatus] = useState(false);
  const [registrationPopup, setRegistrationPopup] = useState(false);

  const getSettingsApi = async () => {
    API.getSettings()
      .then(async (response) => {
        const data = response.data.settings_list;
        data.map((settings) => {
          switch (settings.title.trim()) {
            case Constants.SUBMIT_NOMINATIONS:
              dispatch({
                type: SUBMIT_NOMINATIONS,
                payload: settings.isEnabled,
              });
              dispatch({
                type: SUBMIT_NOMINATIONS_ENCOUNT,
                payload: settings.enabledCount,
              });
              break;
            case Constants.WITHDRAW_NOMINATIONS:
              dispatch({
                type: WITHDRAW_NOMINATIONS,
                payload: settings.isEnabled,
              });
              dispatch({
                type: WITHDRAW_NOMINATIONS_ENCOUNT,
                payload: settings.enabledCount,
              });
              break;
            case Constants.VOTING:
              dispatch({ type: VOTING, payload: settings.isEnabled });
              dispatch({
                type: VOTING_ENCOUNT,
                payload: settings.enabledCount,
              });
              break;
            case Constants.DECLARE_RESULT:
              dispatch({ type: DECLARE_RESULT, payload: settings.isEnabled });
              dispatch({
                type: DECLARE_RESULT_ENCOUNT,
                payload: settings.enabledCount,
              });
              break;
            case Constants.ELECTION_SIGNOFF:
              dispatch({ type: ELECTION_SIGNOFF, payload: settings.isEnabled });
              break;
            case Constants.LOCK_RESULTS:
              dispatch({ type: LOCK_RESULTS, payload: settings.isEnabled });
              break;
            case Constants.ONBOARD_NEW_COMMITTEE:
              dispatch({
                type: ONBOARD_NEW_COMMITTEE,
                payload: settings.isEnabled,
              });
              dispatch({
                type: ONBOARD_NEW_COMMITTEE_ENCOUNT,
                payload: settings.enabledCount,
              });
              break;
            case Constants.PUBLISH_RESULT:
              dispatch({ type: PUBLISH_RESULT, payload: settings.isEnabled });
              break;
            case Constants.PUBLISH_CANDIDATE_LIST:
              dispatch({
                type: PUBLISH_CANDIDATE_LIST,
                payload: settings.isEnabled,
              });
              dispatch({
                type: PUBLISH_CANDIDATE_LIST_ENCOUNT,
                payload: settings.enabledCount,
              });
              break;
            default:
          }
        });
      })
      .catch((e) => {
        console.log("error of settings", e);
      });
  };

  const getElectionTokenApi = async () => {
    API.getElectionToken(
      globalState.state.user.mobile,
      globalState.state.usertoken
    )
      .then(async (response) => {
        getNotificationsApi(response.data.token);
      })
      .catch((e) => {
        console.log("error of settings", e);
      });
  };

  const getNotificationsApi = async (token) => {
    API.getNotifications(token)
      .then(async (response) => {
        setNotificationCount(response.data.notifications.length);
      })
      .catch((e) => {
        console.log("error of notifications", e);
      });
  };

  const registerUserAsVoter = async () => {
    console.log("token", globalState.state.usertoken);
    API.voterRegistration(globalState.state.usertoken)
      .then(async (response) => {
        console.log(response, "responsese");
        getUserInfo();
        setRegistrationPopup(true);
        // setNotificationCount(response.data.notifications.length);
      })
      .catch((e) => {
        console.log("error of registration", e);
      });
  };

  const getUserInfo = async () => {
    const result = await API.getUser(globalState.state.usertoken).then(
      (response) => {
        console.log("Responseses", response);
        dispatch({ type: USER_DATA, payload: response.data });
        dispatch({
          type: REGISTRATION_STATUS,
          payload: null,
        });
        dispatch({
          type: REGISTRATION_STATUS,
          payload: response.data.registration_status,
        });
      }
    );
    // .error((e) => {});
  };

  const providerValue = {
    notificationCount,
    registrationStatus,
    spinnerStatus,
    registration,
    registrationPopup,

    setRegistrationStatus,
    setSpinnerStatus,
    setNotificationCount,
    setRegistration,
    registerUserAsVoter,
    setRegistrationPopup
  };

  useEffect(async () => {
    getSettingsApi();
    // getElectionTokenApi();
    // myVar = setInterval(getElectionTokenApi(), 60000);
  }, []);

  return (
    <LandingContext.Provider value={providerValue}>
      {children}
    </LandingContext.Provider>
  );
};

//TODO: API NOTIFICATION INTEGRATION
