import React, { useContext } from 'react';
import { VotingContext } from './VotingContext';
import  VotingSuccessPopup  from './VotingSuccessPopup';
import  VotingConfirmPopup  from './VotingConfirmPopup';
import PositionsList from "../PositionsList";
import PositionBox from "../PositionBox";
import { Container, Row, Col, Tab, Button} from 'react-bootstrap'
import Timer from '../Timer'
import ConfirmPopup from "../ConfirmPopup"
import { store } from "../../contexts/store";

const VotingContainer = () => {
      const globalState = useContext(store);
      const votingModel = useContext(VotingContext);
      const {
        positionList,
        candidates,
        onSubmit,
        onSave,
        showVoteColours,
        OnVoteClick,
        voted,
        defaultPosition,
        onTabSelect,
        positionName,
        maxVote,
        voteList,
        showPopup,
        setShowPopup,
        message,
        setPositionName,
        setDefaultPosition
      } = votingModel;
    return (
    <section className="middle-wrap pt-5 pb-5">
    <Container>
        <Row className="justify-content-md-center">
            <Col xs sm="12" md="12" lg="12">
                <div className="d-flex justify-content-md-between align-items-md-center">
                <h3 className="text-left"><b>Voting</b></h3>
                {false &&
                <Timer/>
                }
                </div>
                {/* {!voted && globalState.state.voting &&
                <Button variant="danger" size="lg" 
                className="mt-2 position-absolute submit-btn" onClick={()=>onSubmit()}>SUBMIT</Button>
                } */}
                <VotingSuccessPopup/>
                <VotingConfirmPopup/>
                <Row>
                <Tab.Container
                  id="left-tabs-example"
                  className="voting-sect"
                  activeKey={defaultPosition}
                >
                  <PositionsList catidatesData={[...candidates]} screen={"voting"} data={positionList} showVoteColours={showVoteColours} onTabSelect={onTabSelect} voteList={voteList} setPositionName ={setPositionName} setDefaultPosition={setDefaultPosition} voted={voted} votingState={globalState.state.voting } onSubmit={onSubmit} />
                  <PositionBox catidatesData={[...candidates]} screen={"voting"} OnVoteClick={OnVoteClick} voted={voted} positionId={defaultPosition} positionName={positionName} maxVote={maxVote} voteList={[...voteList]} onSave={onSave} isVotingEnables={globalState.state.voting}/>
                </Tab.Container>
                </Row>
            </Col>
        </Row>
        <ConfirmPopup
            showPopup={showPopup}
            setShowPopup={setShowPopup}
            message={message}
            isConfirm={false}
          />
    </Container>
    </section>
    );
}

export default VotingContainer;