import React from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import LandingGrids from "../components/LandingGrids/LandingGrids";
import { LandingContextProvider } from "../components/LandingGrids/LandingContext";

const LandingPage = () => {
  return (
    <div>
      <Header />
      <LandingContextProvider>
        <LandingGrids />
      </LandingContextProvider>
      <Footer />
    </div>
  );
};

export default LandingPage;
