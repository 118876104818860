import { React, memo, useContext } from "react";
import NotificationItem from "../NotificationItem";
import { NotificationContext } from "./NotificationContext";
import { Card } from "react-bootstrap";

const NotificationContainer = () => {
  const notificationModel = useContext(NotificationContext);

  return (
    <>
      {
      notificationModel?.notifications?.map((data) => {
          return (
            <NotificationItem
              key={data?._id}
              title={data?.title}
              date={data?.date}
              content={data?.message}
            />
          );
        })}
      {
        notificationModel?.notifications && notificationModel?.notifications.length===0 &&
        <Card className="notification-card mt-3 mb-3" >
          <Card.Body>
          <div className="alignCenter">
            No notifications yet.
          </div>
          </Card.Body>
        </Card>
      }
    </>
  );
};

export default memo(NotificationContainer);
