import React, { createContext, useState, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { store } from "../../contexts/store";
import api from "../../Api/api";
import {
  USER_LOGIN,
  USER_DATA,
  USER_TOKEN,
  REGISTRATION_STATUS,
  USER_PHONE_NUMBER,
} from "../../contexts/types";
import routes from "../../constants/routes.json";
import constants from "../../constants/constantValues.json";

export const LoginContext = createContext();

export const LoginContextProvider = ({ children }) => {
  const history = useHistory();
  const globalState = useContext(store);
  const { dispatch } = globalState;
  const [phoneNumber, setPhoneNumber] = useState("");
  const [otp, setOtp] = useState(0);
  const [spinnerSignin, setSpinnerSignin] = useState(false);
  const [signinErrorMsg, setSigninErrorMsg] = useState("");
  const [userAuthStatus, setUserAuthStatus] = useState(
    globalState.state.authLogin
  );

  const otpRequest = async () => {
    setSigninErrorMsg("");
    if (phoneNumber.length > 9) {
      dispatch({ type: USER_PHONE_NUMBER, payload: phoneNumber });
      const result = await api
        .userLogin({ phoneNumber })
        .then(() => {
          return true;
        })
        .catch((error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.msg
          ) {
            return error.response.data.msg;
          } else {
            return false;
          }
        });

      return result;
    }
  };

  const verifyOtp = async () => {
    setSpinnerSignin(true);
    setSigninErrorMsg("");
    const result = await api
      .verifyOtp({ phoneNumber, otp })
      .then((response) => {
        setSpinnerSignin(false);
        setSigninErrorMsg("");
        dispatch({ type: USER_TOKEN, payload: response.data.access_token });
        dispatch({ type: USER_LOGIN, payload: true });
        //  getUserInfo(response.data.access_token);
        getSettingsApi(response.data.access_token);
      })
      .catch((error, response) => {
        setSpinnerSignin(false);
        setSigninErrorMsg(error.response.data.msg);
        return false;
      });
  };

  const getUserInfo = async (token) => {
    if (phoneNumber.length > 9) {
      const result = await api.getUser(token).then((response) => {
        dispatch({ type: USER_DATA, payload: response.data });
        dispatch({
          type: REGISTRATION_STATUS,
          payload: response.data.registration_status,
        });
        history.push(routes.LANDING);
      });
    }
  };

  const getSettingsApi = async (token) => {
    api
      .getSettings()
      .then(async (response) => {
        const data = response.data.settings_list.filter(
          (x) => x.title.trim() === constants.MEMBER_PROFILE_EDIT
        );
        if (data.length > 0 && data[0].isEnabled === false) {
          getUserInfo(token);
        } else {
          window.location.href = constants.REDIRECT_PATH;
        }
      })
      .catch((e) => {
        console.log("error of settings", e);
      });
  };

  const providerValue = {
    phoneNumber,
    otp,
    userAuthStatus,

    setPhoneNumber,
    setOtp,
    setUserAuthStatus,
    otpRequest,
    verifyOtp,
    spinnerSignin,
    signinErrorMsg,
    setSigninErrorMsg,
  };
  useEffect(async () => {
    setSpinnerSignin(false);
    setSigninErrorMsg("");
  }, []);

  return (
    <LoginContext.Provider value={providerValue}>
      {children}
    </LoginContext.Provider>
  );
};
