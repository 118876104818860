import React, { createContext, useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import routes from "../../constants/routes.json";
import API from "../../Api/api";
import { store } from "../../contexts/store";
import { USER, SPECIAL_TOKEN } from "../../contexts/types";
import Constants from "../../constants/constantValues";

export const VotingContext = createContext();

export const VotingContextProvider = ({ children }) => {
  let history = useHistory();
  const globalState = useContext(store);
  const { dispatch } = globalState;
  const [positionList, setPositionList] = useState([]);
  const [candidates, setCandidates] = useState([]);
  const [nominations, setNominations] = useState([]);
  const [showVotingConfirm, setShowVotingConfirm] = useState(false);
  const [votingSuccess, setVotingSuccess] = useState(false);
  const [confirmCheck, setConfirmCheck] = useState(false);
  const [showVoteColours, setShowVoteColours] = useState(false);
  const [voted, setVoted] = useState(true);
  const [unvotedPositions, setUnvotedPositions] = useState([]);
  const [defaultPosition, setDefaultPosition] = useState(null);
  const [positionName, setPositionName] = useState("");
  const [maxVote, setMaxVote] = useState(1);
  const [voteList, setVoteList] = useState([]);
  const [voteMode, setVoteMode] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [message, setMessage] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const [infoMessage, setInfoMessage] = useState("");

  const getAssociationPositionsApi = async () => {
    let positions = [];
    API.getAssociationPositions()
      .then(async (response) => {
        if (
          globalState.state.user &&
          globalState.state.user.zone &&
          globalState.state.user.zone.name
        ) {
          positions = response.data.association_list.filter(
            (x) =>
              x.targetVotersZone &&
              x.targetVotersZone !== null &&
              x.targetVotersZone?.name !== undefined &&
              (x.targetVotersZone.name.toLowerCase() ===
                globalState.state.user.zone.name.toLowerCase() ||
                x.targetVotersZone.name.toLowerCase() === "all")
          );
        } else {
          positions = response.data.association_list.filter(
            (x) =>
              x.targetVotersZone &&
              x.targetVotersZone !== null &&
              x.targetVotersZone?._id !== undefined &&
              x.targetVotersZone.name.toLowerCase() === "all"
          );
        }
        setPositionList(positions);
        setDefaultPosition(positions.length > 0 ? positions[0]._id : "");
        // setPositionName(positions.length>0?positions[0].name:"");
        setMaxVote(positions.length > 0 ? positions[0].totalPositions : 0);
      })
      .catch((e) => {
        console.log("error of position list", e);
      });
  };

  const OnVoteClick = (posid, id) => {
    let others = [];
    let candidateindex = -1;
    const list = candidates.filter((x) => x.positionId === posid);
    if (list[0].candidateId !== undefined) {
      candidateindex = list[0].candidateId.indexOf(id);
    }
    if (candidateindex === -1) {
      if (list[0].candidateId && list[0].candidateId.length < maxVote) {
        list[0].candidateId.push(id);
      } else {
        list[0].candidateId = [id];
      }
    } else {
      list[0].candidateId.splice(candidateindex, 1);
    }
    let index = candidates.findIndex((x) => x.positionId === posid);
    others = candidates.slice();
    others.splice(index, 1);
    others.push(list[0]);
    setCandidates(others);

    let votes = [];
    let candidate = -1;
    const voteposition = voteList.filter((x) => x.positionId === posid);
    if (voteposition.length > 0) {
      if (voteposition[0].candidateId !== undefined) {
        candidate = voteposition[0].candidateId.indexOf(id);
      }
      if (candidate === -1) {
        if (voteposition[0].candidateId.length < maxVote) {
          voteposition[0].candidateId.push(id);
        } else {
          setMessage(
            "Maximum number of votes for the position '" +
              positionName +
              "' should be " +
              maxVote
          );
          setShowPopup(true);
        }
      } else {
        voteposition[0].candidateId.splice(candidate, 1);
      }
      let index = voteList.findIndex((x) => x.positionId === posid);
      votes = voteList.slice();
      votes.splice(index, 1);
      votes.push(voteposition[0]);
    } else {
      votes = voteList;
      let data = { positionId: posid, candidateId: [id] };
      votes.push(data);
    }
    setVoteList(votes);
  };

  const getSpecialTokenApi = async (mode) => {
    API.getSpecialToken(
      globalState.state.user.mobile,
      1,
      globalState.state.usertoken
    )
      .then(async (response) => {
        const specialtocken = response.data.token;
        dispatch({ type: SPECIAL_TOKEN, payload: specialtocken });
        saveVotesApi(mode, specialtocken);
      })
      .catch((e) => {
        console.log("error of special tocken", e);
      });
  };
  const getUnvotedPositions = () => {
    let positions = [...positionList];
    let posindex = -1;
    let posCandidates = [];
    let posindex2 = -1;
    voteList.map((vote) => {
      if (vote.candidateId.length > 0) {
        posindex = positions.findIndex((x) => x._id === vote.positionId);
        if (posindex >= 0) {
          positions.splice(posindex, 1);
        }
      }
    });
    positionList.map((pos, index) => {
      if (candidates.length > 0) {
        posCandidates = candidates.filter((x) => x.positionId === pos._id);
        posindex2 = positions.findIndex(
          (x) => x._id === pos._id && x.totalPositions >= posCandidates.length
        );
        if (posindex2 >= 0) {
          positions.splice(posindex2, 1);
        }
      }
    });
    setUnvotedPositions(positions);
  };
  const onSubmit = async () => {
    const settingsStatus = await getSettingsApi("save");
    if (settingsStatus) {
      getUnvotedPositions();
      setShowVotingConfirm(true);
      setConfirmCheck(false);
      setShowVoteColours(true);
    }
  };
  const onSave = async () => {
    const settingsStatus = await getSettingsApi("save");
    if (settingsStatus) {
      getSpecialTokenApi("Draft");
      setVoteMode("Draft");
      setShowVoteColours(true);
    }
  };
  const onConfirmClick = async () => {
    const settingsStatus = await getSettingsApi("save");
    if (settingsStatus) {
      if (confirmCheck) {
        getSpecialTokenApi("Submitted");
        setVoteMode("Submitted");
      }
    }
  };

  const getSettingsApi = async (type) => {
    const status = await API.getSettings().then(async (response) => {
      const data = response.data.settings_list.filter(
        (x) => x.title.trim() === Constants.VOTING && x.isEnabled === true
      );
      if (data && data.length > 0) {
        return true;
      } else {
        if (type === "save") {
          setInfoMessage(
            "Voting time is already over. You can no longer Vote for this election."
          );
          setIsSuccess(false);
          setVotingSuccess(true);
          return false;
        } else {
          return false;
        }
      }
    });
    return status;
  };

  const onCheckedValueChange = () => {
    setConfirmCheck(!confirmCheck);
  };
  const OnBacktoHomeClick = () => {
    setVotingSuccess(false);
    history.push({
      pathname: routes.LANDING,
    });
  };
  const onTabSelect = (id, name, count) => {
    setDefaultPosition(id);
    setPositionName(name);
    setMaxVote(count);
    window.scrollTo(0, 0);
  };
  const getVotesFromApi = async () => {
    API.getVotes(globalState.state.user._id)
      .then(async (response) => {
        if (response.data.vote_list && response.data.vote_list.length > 0) {
          const settingsStatus = await getSettingsApi("get");
          if (settingsStatus) {
            setVoteList(response.data.vote_list[0].votes);
          } else {
            if (response.data.vote_list[0].mode === "Submitted") {
              setVoteList(response.data.vote_list[0].votes);
            }
          }
          setVoted(
            response.data.vote_list[0].mode === "Submitted" ? true : false
          );
          getUnvotedPositions();
          setShowVoteColours(true);
          if (response.data.vote_list[0].mode === "Submitted") {
            setInfoMessage("You have already cast your vote");
            setIsSuccess(false);
            setVotingSuccess(true);
          } else if (
            globalState.state.votingEnableCount === 1 &&
            !globalState.state.voting &&
            response.data.vote_list[0].mode !== "Submitted"
          ) {
            setInfoMessage(
              "Voting time is already over. You can no longer Vote for this election."
            );
            setIsSuccess(false);
            setVotingSuccess(true);
          }
        } else {
          if (
            globalState.state.votingEnableCount === 1 &&
            !globalState.state.voting
          ) {
            setInfoMessage(
              "Voting time is already over. You can no longer Vote for this election."
            );
            setIsSuccess(false);
            setVotingSuccess(true);
          }
        }
      })
      .catch((e) => {
        console.log("error of Get Votes", e);
      });
  };

  const saveVotesApi = (mode, specialTocken) => {
    let list = [];
    voteList.map((vote) => {
      let data = { positionId: vote.positionId, candidateId: vote.candidateId };
      list.push(data);
    });

    API.saveVotes(
      globalState.state.user._id,
      mode,
      list,
      specialTocken,
      globalState.state.user.mobile
    )
      .then(async (response) => {
        setVoted(mode === "Submitted" ? true : false);
        setShowVotingConfirm(false);
        setIsSuccess(true);
        setVotingSuccess(true);
      })
      .catch((e) => {
        console.log("error of Save Votes", e);
      });
  };
  const getNominationsApi = async () => {
    API.getAllNominations()
      .then(async (response) => {
        setCandidates(
          shuffleArray(
            response.data.nomination_list.filter(
              (x) =>
                x.status === "Approved" && x._id !== undefined && x._id !== null
            )
          )
        );
        setNominations(
          shuffleArray(
            response.data.nomination_list.filter(
              (x) =>
                x.status === "Approved" && x._id !== undefined && x._id !== null
            )
          )
        );
      })
      .catch((e) => {
        console.log("error of Nominations Api ", e);
      });
  };

  const shuffleArray = (candidateList) => {
    let i = candidateList.length - 1;
    for (; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      const temp = candidateList[i];
      candidateList[i] = candidateList[j];
      candidateList[j] = temp;
      candidateList[i].slno = i;
      candidateList[j].slno = j;
    }
    return candidateList;
  };

  const setDefaultPositionName = () => {
    let defaultPosId = "";
    let posCandidates = [];
    positionList.map((pos, index) => {
      posCandidates = candidates.filter((x) => x.positionId === pos._id);
      if (
        defaultPosId === "" &&
        pos._id !== "" &&
        pos.totalPositions < posCandidates.length
      ) {
        defaultPosId = pos._id;
        setDefaultPosition(pos._id);
        setPositionName(pos.name);
      }
    });
  };

  const initialize = () => {
    getAssociationPositionsApi();
    getNominationsApi();
    getVotesFromApi();
    setVoted(false);
    setVotingSuccess(false);
  };

  const providerValue = {
    positionList,
    setPositionList,
    defaultPosition,
    setDefaultPosition,
    candidates,
    setCandidates,
    OnVoteClick,
    onSubmit,
    onSave,
    showVotingConfirm,
    setShowVotingConfirm,
    onConfirmClick,
    votingSuccess,
    setVotingSuccess,
    confirmCheck,
    setConfirmCheck,
    onCheckedValueChange,
    OnBacktoHomeClick,
    showVoteColours,
    setShowVoteColours,
    voted,
    onTabSelect,
    positionName,
    setPositionName,
    maxVote,
    setMaxVote,
    voteList,
    unvotedPositions,
    setUnvotedPositions,
    voteMode,
    showPopup,
    setShowPopup,
    message,
    isSuccess,
    infoMessage,
    initialize
  };

  useEffect(async () => {
    setDefaultPositionName();
  }, [nominations, positionList]);



  useEffect(async () => {
    initialize();
  }, []);

  return (
    <VotingContext.Provider value={providerValue}>
      {children}
    </VotingContext.Provider>
  );
};
