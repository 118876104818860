import React from 'react'
import {AssociationContextProvider} from '../components/Association/AssociationContext'
import AssociationContainer from '../components/Association/AssociationContainer'
import Footer from '../components/Footer'
import Header from '../components/Header'
import '../containers/Voting.css';
const NewAssociationPage = () => {
    return (
        <>
           <Header/>
                <AssociationContextProvider>
                    <AssociationContainer />
                </AssociationContextProvider>
           <Footer/>
        </>
    )
}

export default NewAssociationPage
