import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Footer from "../components/Footer";
import Header from "../components/Header";
import { NotificationContextProvider } from "../components/NotificationContainer/NotificationContext";
import NotificationContainer from "../components/NotificationContainer/NotificationsContainer";

const NotificationsPage = () => {
  return (
    <>
      <Header />
      <NotificationContextProvider>
        <section className="middle-wrap pt-5 pb-5">
          <Container>
            <Row className="justify-content-md-center">
              <Col xs sm="12" md="12" lg="9">
                <h3 className="text-center">
                  <b>Notifications</b>
                </h3>
                <NotificationContainer />
              </Col>
            </Row>
          </Container>
        </section>
      </NotificationContextProvider>
      <Footer />
    </>
  );
};

export default NotificationsPage;
