import React,{ useEffect,useContext } from 'react';
import { Card, Col, Button, Image } from 'react-bootstrap';
import imgCandidate from "../assets/img-avatar.png";
import Icons from './Icons';
import { store } from "../contexts/store";

// votingEnableCount

const CardCandidate = ({
    candidate,
    screen,
    position,
    positionId,
    candidateId,
    voted,
    OnVoteClick}) => {
    const globalState = useContext(store);
    return (
        <Col xs="12" sm="6" md="4" lg={screen && screen==="association"?"3":"4"} className="mt-2 mb-2 "  >
            <Card className="assosiate-box">
                <Image src={candidate.profileImage?candidate.profileImage:imgCandidate}  onError={(e) => { e.target.onerror = null; e.target.src = imgCandidate }} alt="" roundedCircle className="w-50 mx-auto mt-3 imgdiv" />
                <Card.Body className={screen ==="voting" && ((candidateId && candidateId.length> 0 && candidateId.indexOf(candidate._id) !== -1) || (!voted && globalState.state.voting)) ?"divcardbody":""}>
                    <Card.Title className="d-flex justify-content-center alignCenter wraptxt"><b>{candidate.name}</b></Card.Title>
                    {
                        screen && screen!=="association" &&
                        <>
                        <div className="divaddress min-height-unset">{candidate.pfNumber && candidate.pfNumber!==""?("PF#: "+candidate.pfNumber):"" }</div>
                        <div className="divaddress">{(candidate.branch && candidate.branch!==""?(candidate.branch+" Branch"):(candidate.address ?candidate.address:""))}</div>
                        </>
                    }
                    {screen && screen==="association" &&
                    <Card.Text className="d-flex justify-content-center text-center dminHeight">{position}</Card.Text>
                    }
                    {screen && screen ==="voting" && ((candidateId && candidateId.length> 0 && candidateId.indexOf(candidate._id) !== -1) || (!voted && globalState.state.voting)) &&
                    <div className={"text-center btnbottom " + (candidateId && candidateId.length >0 && candidateId.indexOf(candidate._id) !== -1?"green-bgbtns":(voted?"":"green-btns"))}>
                        <Button disabled={ (voted|| (globalState.state.votingEnableCount===1 && !globalState.state.voting)) ?true:false} variant="outline-primary" onClick={()=>OnVoteClick(positionId,candidate._id)} className=" mt-2">{(candidateId && candidateId.length >0 && candidateId.indexOf(candidate._id) !== -1)?Icons.like: ""}{(candidateId && candidateId.length> 0 && candidateId.indexOf(candidate._id) !== -1)?"  Voted": "Vote"}</Button>
                   </div>
                    }                   
                </Card.Body>
            </Card>
        </Col>

    )
}

export default CardCandidate
