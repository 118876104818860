import React from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import CandidateContainer from "../components/CandidateList/CandidateContainer";
import { CandidateListContextProvider } from "../components/CandidateList/CandidateListContext";

const CandidateList = () => {

  return (
    <>
      <Header />
      <CandidateListContextProvider>
        <CandidateContainer />
      </CandidateListContextProvider>
      <Footer />
    </>
  );
};

export default CandidateList;
