import React from "react";
import { Card } from "react-bootstrap";

const NotificationItem = ({ title, date, content }) => {
  return (
    <Card className="notification-card mt-3 mb-3" >
      <Card.Body>
        <div className="d-flex justify-content-between">
          <Card.Title>
            <b>{title}</b>
          </Card.Title>
          <span>{date}</span>
        </div>
        <Card.Text>{content}</Card.Text>
      </Card.Body>
    </Card>
  );
};

export default NotificationItem;
