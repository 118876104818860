import React from "react";
import Login from "../components/Login/Login";
import { LoginContextProvider } from "../components/Login/LoginContext";

const LoginPage = () => {
  return (
    <div>
      {/* <Navbar /> */}
      <LoginContextProvider>
        <Login />
      </LoginContextProvider>
    </div>
  );
};

export default LoginPage;