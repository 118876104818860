import React from "react";
import NavbarMenu from "./NavbarMenu";
import Topbar from "./Topbar";
const Header = () => {
  return <header>
            <Topbar/>
            <NavbarMenu/>
        </header>;
};

export default Header;