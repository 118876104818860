import { React, memo } from "react";
import { Row, Col, Form } from "react-bootstrap";

const SelectPicker = ({ list,selectedItem,selectedValueChange,placeholder}) => {
  return (
    <>
      <Row>
        <Col>
          <Form.Group controlId="exampleForm.ControlSelect1">
            <Form.Control as="select" placeholder={placeholder&&placeholder!==""?placeholder:""} value={selectedItem} onChange={(e) => selectedValueChange(e.target.value)} size="lg">
            <option key={1} value={""}>{"Select"}</option>
              {list &&
                list.map((item) => {
                  return <option key={item._id} value={item._id}>{item.name}</option>;
                })}
                
            </Form.Control>
          </Form.Group>
        </Col>
      </Row>
    </>
  );
};

export default memo(SelectPicker);
