import React from "react";
import "../components/Topbar.css";
import { Navbar, Container, Row, Col, Image } from "react-bootstrap";
import Logo from "../assets/educational-society.png";
import Icons from "../components/Icons";

const Topbar = () => {
  return (
    <div>
      <Navbar bg="light">
        <Container style={{ width: "100%" }}>
          <Row className="d-flex justify-content-between align-items-center">
            <Col xs={12} md={8}>
              <div className="" style={{ maxWidth: "250px" }}>
                <Navbar.Brand href="#home">
                  {<Image fluid src={Logo} alt="FBOAES" style={{marginLeft:'8rem', maxHeight:'100px'}} />}
                </Navbar.Brand>
              </div>
            </Col>
          </Row>
        </Container>
        <Col className="d-none d-sm-none d-md-block" xs={12} md={4} style={{marginRight:'8rem'}}>
          <div className="d-flex justify-content-end align-items-center mt-3  ">
            {Icons.phone}
            <h3>0484-2624646</h3>
          </div>
        </Col>
      </Navbar>
    </div>
  );
};

export default Topbar;
