import React, { createContext, useReducer } from "react";
import {
  TEST_VARIABLE,
  USER_DATA,
  USER_LOGIN,
  USER_TOKEN,
  SPECIAL_TOKEN,
  SUBMIT_NOMINATIONS,
  WITHDRAW_NOMINATIONS,
  VOTING,
  DECLARE_RESULT,
  ELECTION_SIGNOFF,
  LOCK_RESULTS,
  ONBOARD_NEW_COMMITTEE,
  PUBLISH_RESULT,
  PUBLISH_CANDIDATE_LIST,
  SUBMIT_NOMINATIONS_ENCOUNT,
  VOTING_ENCOUNT,
  PUBLISH_CANDIDATE_LIST_ENCOUNT,
  ONBOARD_NEW_COMMITTEE_ENCOUNT,
  DECLARE_RESULT_ENCOUNT,
  WITHDRAW_NOMINATIONS_ENCOUNT,
  REGISTRATION_STATUS,
  USER_PHONE_NUMBER,
} from "./types";

const initialState = {
  authLogin: true,
  testVariable: "",
  user: [],
  usertoken: "",
  specialtoken: "",
  submitNominations: false,
  withdrawNominations: false,
  withdrawNominationsEnableCount: 0,
  voting: false,
  declareResults: false,
  electionSignoff: false,
  lockResult: false,
  newCommittee: false,
  publishResult: false,
  publishCandidateList: false,
  nominationEnableCount: 0,
  votingEnableCount: 0,
  publishCandidateListEnableCount: 0,
  OnboardNewCommitteeEnableCount: 0,
  publishResultEnableCount: 0,
  registrationStatus: false,
  userPhoneNumber: 0,
};

const store = createContext(initialState);
const { Provider } = store;

const reducer = (state, action) => {
  switch (action.type) {
    case TEST_VARIABLE:
      return {
        ...state,
        testVariable: action.payload,
      };
    case USER_LOGIN:
      return {
        ...state,
        authLogin: action.payload,
      };
    case USER_DATA:
      return {
        ...state,
        user: action.payload,
      };
    case USER_TOKEN:
      return {
        ...state,
        usertoken: action.payload,
      };
    case SPECIAL_TOKEN:
      return {
        ...state,
        specialtoken: action.payload,
      };
    case SUBMIT_NOMINATIONS:
      return {
        ...state,
        submitNominations: action.payload,
      };
    case WITHDRAW_NOMINATIONS:
      return {
        ...state,
        withdrawNominations: action.payload,
      };
    case WITHDRAW_NOMINATIONS_ENCOUNT:
      return {
        ...state,
        withdrawNominationsEnableCount: action.payload,
      };
    case VOTING:
      return {
        ...state,
        voting: action.payload,
      };
    case DECLARE_RESULT:
      return {
        ...state,
        declareResults: action.payload,
      };
    case DECLARE_RESULT_ENCOUNT:
      return {
        ...state,
        publishResultEnableCount: action.payload,
      };
    case LOCK_RESULTS:
      return {
        ...state,
        lockResult: action.payload,
      };
    case ONBOARD_NEW_COMMITTEE:
      return {
        ...state,
        newCommittee: action.payload,
      };
    case PUBLISH_RESULT:
      return {
        ...state,
        publishResult: action.payload,
      };
    case SUBMIT_NOMINATIONS_ENCOUNT:
      return {
        ...state,
        nominationEnableCount: action.payload,
      };
    case VOTING_ENCOUNT:
      return {
        ...state,
        votingEnableCount: action.payload,
      };
    case ONBOARD_NEW_COMMITTEE_ENCOUNT:
      return {
        ...state,
        OnboardNewCommitteeEnableCount: action.payload,
      };
    case PUBLISH_CANDIDATE_LIST:
      return {
        ...state,
        publishCandidateList: action.payload,
      };
    case PUBLISH_CANDIDATE_LIST_ENCOUNT:
      return {
        ...state,
        publishCandidateListEnableCount: action.payload,
      };
    case REGISTRATION_STATUS:
      return {
        ...state,
        registrationStatus: action.payload,
      };
    case USER_PHONE_NUMBER:
      return {
        ...state,
        userPhoneNumber: action.payload,
      };
    default:
      return state;
  }
};

const StateProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  return <Provider value={{ state, dispatch }}>{children}</Provider>;
};

export { store, StateProvider };
