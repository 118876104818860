import React,{ useContext } from "react";
import '../../containers/nomiationPagestyle.css'
import NominationSubList from "./NominationSubList";
import {store} from "../../contexts/store";

const NominationSubmission = ({nominations,onWidthdarwClick,allowWithdraw,positions}) => {
    const globalState = useContext(store);
    const { dispatch } = globalState;
    return (
        <div>
           <div className={"nomini-submt-sect "+(globalState.state.withdrawNominations || positions.length===0 ||(!globalState.state.submitNominations && globalState.state.nominationEnableCount===1 )?"nomini-submt-withdraw":"divoverflowY w-25")}>
               <h3>My nominations</h3>
               {
               nominations && nominations.map((nomination, index) => {
                return (
                <NominationSubList key={index} nomination={nomination} onWidthdarwClick={onWidthdarwClick} allowWithdraw={allowWithdraw} />
                )})
                }
                {
                    nominations.length===0&&
                    <div className={"noDataMsg "+ ((globalState.state.withdrawNominations || positions.length===0 ||(!globalState.state.submitNominations && globalState.state.nominationEnableCount===1 ))? "alignCenter":"")} >No nominations submitted</div>
                }
           </div>
        </div>
    )
}

export default NominationSubmission
