import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Icons from './Icons'

  const ConfirmPopup = ({
    showPopup,
    setShowPopup,
    onConfirmClick,
    message,
    isConfirm
  }) => {

  return (
<Modal className="home-modals" show={showPopup} >
    <Modal.Header className="contnt-center" closeButton onClick={()=>setShowPopup(false)}>
    <div className="check-circle-img">{Icons.warning2}</div>
        <Modal.Title className="popupmsg" >{message}</Modal.Title>
    </Modal.Header>
    {isConfirm &&
     <Modal.Footer className="popupfooter">
     <p className="text-primary btn mt-2 cursotpointer btnMargin" onClick={()=>setShowPopup(false)}>
       Cancel
     </p>
     <Button
       classBame="submit-btn-orange"
       variant="primary"
       onClick={() => {
         onConfirmClick();
       }}
     >
       {"Confirm"}
     </Button>
   </Modal.Footer>
  }
  {!isConfirm &&
     <Modal.Footer className="popupfooter">
     <Button
       classBame="submit-btn-orange"
       variant="primary"
       onClick={()=>setShowPopup(false)}
     >
       {"Ok"}
     </Button>
   </Modal.Footer>
  }
</Modal>
);
};

export default ConfirmPopup;
