export const TEST_VARIABLE = 'TEST_VARIABLE';
export const USER_LOGIN = "USER_LOGIN";
export const USER_LOGOUT = "USER_LOGOUT";
export const USER_DATA = "USER_DATA";
export const USER_TOKEN = "USER_TOKEN";
export const SPECIAL_TOKEN = "SPECIAL_TOKEN";
export const SUBMIT_NOMINATIONS = "SUBMIT_NOMINATIONS";
export const WITHDRAW_NOMINATIONS = "WITHDRAW_NOMINATIONS";
export const VOTING = "VOTING";
export const DECLARE_RESULT = "DECLARE_RESULT";
export const PUBLISH_RESULT = "PUBLISH_RESULT";
export const ELECTION_SIGNOFF = "ELECTION_SIGNOFF";
export const LOCK_RESULTS = "LOCK_RESULTS";
export const ONBOARD_NEW_COMMITTEE = "ONBOARD_NEW_COMMITTEE";
export const PUBLISH_CANDIDATE_LIST="PUBLISH_CANDIDATE_LIST";
export const SUBMIT_NOMINATIONS_ENCOUNT = "SUBMIT_NOMINATIONS_ENCOUNT";
export const VOTING_ENCOUNT= "VOTING_ENCOUNT";
export const PUBLISH_CANDIDATE_LIST_ENCOUNT="PUBLISH_CANDIDATE_LIST_ENCOUNT";
export const ONBOARD_NEW_COMMITTEE_ENCOUNT = "ONBOARD_NEW_COMMITTEE_ENCOUNT";
export const DECLARE_RESULT_ENCOUNT = "DECLARE_RESULT_ENCOUNT";
export const WITHDRAW_NOMINATIONS_ENCOUNT = "WITHDRAW_NOMINATIONS_ENCOUNT";
export const REGISTRATION_STATUS = "REGISTRATION_STATUS"
export const USER_PHONE_NUMBER = "USER_PHONE_NUMBER"
