import React,{useContext} from "react";
import '../components/NavbarMenu.css'
import { Navbar, Container, Nav, NavDropdown } from 'react-bootstrap';
import { store } from "../contexts/store";
import { USER_LOGIN,USER_DATA,USER_TOKEN } from "../contexts/types";
import { useHistory, useLocation} from "react-router-dom";
import routes from '../constants/routes.json';

const NavbarMenu = () => {
  const location = useLocation();
  const history = useHistory();
  const globalState = useContext(store);
  const { dispatch } = globalState;
  const onLogoutClick=()=>{
    dispatch({ type: USER_TOKEN, payload: "" });
    dispatch({ type: USER_LOGIN, payload: false });
    dispatch({ type: USER_DATA, payload: [] });
    history.push(routes.LOGIN);
  }
  const onBackClick=()=>{
    history.push(routes.LANDING);
  }
  return <>
          <Navbar bg="primary">
            <Container>
              <Nav className="mr-auto">
              {
                <Nav.Link onClick={()=>onBackClick()} className={"text-white btn btn-link " +(location.pathname===routes.LANDING?"hidediv":"")}>Back</Nav.Link>
              }
              </Nav>
              <h3 className="text-white mt-2 d-none d-sm-none d-md-block">Elections 2023</h3>
              <Nav className="ml-auto">
                <NavDropdown className="text-white top-drop-nav" title={globalState.state.user.name} id="basic-nav-dropdown">
                  <NavDropdown.Item onClick={()=>onLogoutClick()}>Logout</NavDropdown.Item>
                </NavDropdown>
              </Nav>
            </Container>
          </Navbar>
        </>;
};

export default NavbarMenu;
