import React from 'react'
import Footer from '../components/Footer'
import Header from '../components/Header'
import './ResultSlider.css'
import ResultContainer from "../components/Result/ResultContainer";
import { ResultContextProvider } from "../components/Result/ResultContext";

const Result = () => {
    
    return (
        <>
            <Header/>
                <ResultContextProvider>
                    <ResultContainer />
                </ResultContextProvider>
            <Footer/>
        </>
    )
}

export default Result
