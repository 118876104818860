import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import routes from "./constants/routes.json";
import constantValues from "./constants/constantValues.json";
import LandingPage from "./containers/LandingPage";
import NominationsPage from "./containers/NominationsPage";
import NotificationsPage from "./containers/NotificationsPage";
import NewAssociationPage from "./containers/NewAssociationPage";
import CandidateList from "./containers/CandidateList";
import Result from "./containers/Result";
import Voting from "./containers/Voting";
import PrivateRoute from "./PrivateRoute";
import LoginPage from "./containers/LoginPage";
import IdleTimer, { useIdleTimer } from "react-idle-timer";

const App = () => {
  const env = process.env.REACT_APP_ENV;
  const handleOnIdle = (event) => {
    if (env !== "development" && window.location !== routes.LOGIN) {
      window.location.href = routes.LOGIN;
    }
  };

  const { getRemainingTime, getLastActiveTime } = useIdleTimer({
    timeout: 1000 * 60 * constantValues.TIMEOUT,
    onIdle: handleOnIdle,
    debounce: 250,
  });
  const preventRightClick = () => {
    // disable right click
    document.addEventListener("contextmenu", (event) => event.preventDefault());

    document.onkeydown = function (e) {
      console.log(e)
      // disable F12 key
      if (e.keyCode == 123) {
        return false;
      }

      // disable I key
      if (e.ctrlKey && e.shiftKey && e.keyCode == 73) {
        return false;
      }

      // disable J key
      if (e.ctrlKey && e.shiftKey && e.keyCode == 74) {
        return false;
      }

      // disable U key
      if (e.ctrlKey && e.keyCode == 85) {
        return false;
      }
    };
  };

  useEffect(() => {
    if (env !== "development") preventRightClick();
  }, []);

  return (
    <div>
      <IdleTimer
        timeout={1000 * 60 * constantValues.TIMEOUT}
        onIdle={handleOnIdle}
        debounce={250}
      />
      <div
        className={"App " + (env !== "development" ? "no_selection" : "")}
        onContextMenu={(e) =>
          env !== "development" ? e.preventDefault() : void 0
        }
      >
        <Router>
          <Switch>
            <Route path={routes.LOGIN} component={LoginPage} exact />
            <PrivateRoute exact path={routes.LANDING} component={LandingPage} />
            <PrivateRoute
              exact
              path={routes.NOMINATIONS}
              component={NominationsPage}
            />
            <PrivateRoute
              exact
              path={routes.NOTIFICATIONS}
              component={NotificationsPage}
            />
            <PrivateRoute
              exact
              path={routes.NEWASSOCIATION}
              component={NewAssociationPage}
            />
            <PrivateRoute
              exact
              path={routes.CANDIDATELIST}
              component={CandidateList}
            />
            <PrivateRoute exact path={routes.RESULT} component={Result} />
            <PrivateRoute exact path={routes.Voting} component={Voting} />
          </Switch>
        </Router>
      </div>
    </div>
  );
};

export default App;
