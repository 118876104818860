import React, { useContext } from 'react';
import { CandidateListContext } from './CandidateListContext';
import PositionsList from "../PositionsList";
import PositionBox from "../PositionBox";
import { Container,Col, Card, Tab, Row } from "react-bootstrap";

const CandidateContainer = () => {
      const candidatesListModel = useContext(CandidateListContext);
      const {
        positionList,
        candidates,
        onTabSelect,
        defaultPosition,
        positionName
      } = candidatesListModel;
    return (
    <section className="middle-wrap pt-5 pb-5">
        <Container>
          <Row className="justify-content-md-center">
            <Col xs sm="12" md="12" lg="12">
              <h3 className="text-left">
                <b>Candidate List</b>
              </h3>
              <Row>
                <Tab.Container
                  id="left-tabs-example"
                  className="voting-sect"
                  activeKey={defaultPosition}
                >
                  <PositionsList catidatesData={candidates} screen={"candidates"} data={positionList} onTabSelect={onTabSelect} />
                  <PositionBox catidatesData={candidates} screen={"candidates"} positionId={defaultPosition} positionName={positionName}/>
                </Tab.Container>
              </Row>
            </Col>
          </Row>
        </Container>
    </section>
    );
}

export default CandidateContainer;