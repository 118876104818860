import React, { useContext } from "react";
import { VotingContext } from "./VotingContext";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Icons from "../Icons";

const VotingSuccessPopup = () => {
  const votingModel = useContext(VotingContext);
  const {
    votingSuccess,
    setVotingSuccess,
    OnBacktoHomeClick,
    voteMode,
    isSuccess,
    infoMessage,
    initialize,
  } = votingModel;

  return (
    <Modal className="home-modals" show={votingSuccess}>
      <Modal.Header
        className="contnt-center"
        closeButton
        onClick={() => setVotingSuccess(false)}
      >
        <div className="check-circle-img">
          {isSuccess ? Icons.successTick : Icons.info}
        </div>
        {/* <Modal.Title> */}
        <span style={{fontWeight:'bold', fontSize:'20px'}}>
          {isSuccess
            ? voteMode === "Submitted"
              ? "Your Vote is recorded!"
              : "Your DRAFT voting has been recorded. You have to click on FINAL SUBMIT to complete your VOTING PROCESS."
            : infoMessage}
        {/* </Modal.Title> */}
        </span>
      </Modal.Header>

      <Modal.Footer className="d-flex justify-content-center">
        <Button
          variant="primary"
          onClick={() => (isSuccess ? initialize() : setVotingSuccess(false))}
        >
          {isSuccess ? "OK" : "Ok"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default VotingSuccessPopup;
