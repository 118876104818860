import React, { useContext } from 'react';
import { AssociationContext } from './AssociationContext';
import { Container,Col, Card, Row } from "react-bootstrap";
import CardCandidate from "../CardCandidate";

const AssociationContainer = () => {
      const associationModel = useContext(AssociationContext);
      const {
        association
      } = associationModel;
    return (
        <section className="middle-wrap pt-5 pb-5">
            <Container>
                <Row className="voting-sect justify-content-md-start">
                    <Col xs sm="12" md="12" lg="12">
                        <h3 className="text-left"><b>EC 2021 - 23</b></h3>
                        <Card className=" mt-3 mb-3">
                            <Card.Body>
                                <Row>
                                {association && association.map((associationData, index) => {
                                     return (
                                    associationData.results && associationData.results.filter(x=>x.isWinner===true).map((result, index) => {
                                return (
                                    <CardCandidate candidate={result.nomination?result.nomination:[]} screen={"association"} position={associationData.association_position?associationData.association_position.name:""} positionId={associationData.association_position?associationData.association_position._id:""}/>
                                    )}))
                                })
                                }
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}

export default AssociationContainer;