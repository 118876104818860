import React, { useContext } from "react";
import { Col, Container, Row, Form ,Button, Modal} from "react-bootstrap";
import SelectPicker from "../SelectPicker";
import InfoCard from "../InfoCard";
import TermsBlock from "../TermsBlock";
import { NominationContext } from "./NominationContext";
import NominationSubmission from "./NominationSubmission";
import { store } from "../../contexts/store";
import ConfirmPopup from "../ConfirmPopup";
import "../../containers/nomiationPagestyle.css";
import Icons from "../../components/Icons";

const NominationContainer = () => {
  const globalState = useContext(store);
  const NominationModel = useContext(NominationContext);
  const { positionList } = NominationModel;
  return (
    <>
      <section className="middle-wrap pt-5 pb-5">
        <Container className="width-position">
          {!globalState.state.withdrawNominations &&
            NominationModel?.positionList.length > 0 &&
            globalState.state.submitNominations && (
              <Form className="nomini-form">
                <div className="nomini-form-width">
                  <Row className="justify-content-md-center">
                    <Col xs sm="12" md="12" lg="4">
                      <h3 className="text-center">
                        <b>Submit Nomination</b>
                      </h3>
                      <label className="text-center d-block">Position</label>
                      <SelectPicker
                        list={NominationModel.positionList}
                        selectedItem={NominationModel.selectedItem}
                        selectedValueChange={
                          NominationModel.selectedValueChange
                        }
                      />
                    </Col>
                  </Row>
                  <Row className="justify-content-md-center">
                    <Col xs sm="12" md="12" lg="7" xl="6">
                      <InfoCard
                        data={NominationModel}
                        location={NominationModel.location}
                        setLocation={NominationModel.setLocation}
                        mandatoryValid={NominationModel.mandatoryValid}
                        setMandatoryValid={NominationModel.setMandatoryValid}
                        setSubmitDisable={NominationModel.setSubmitDisable}
                      />
                    </Col>
                  </Row>
                  <Row className="justify-content-md-center">
                    <Col xs sm="12" md="12" lg="6" xl="5">
                      <TermsBlock
                        candidateName={NominationModel.candidateName}
                        confirmationStatus={
                          NominationModel.candidateConfirmation
                        }
                        declarationStatus={NominationModel.memberDeclaration}
                        confirmation={NominationModel.setCandidateConfirmation}
                        declaration={NominationModel.setMemberDeclaration}
                        OnSubmitClick={NominationModel.OnSubmitClick}
                        OnCancelClick={NominationModel.OnCancelClick}
                        onBacktoHomeClick={NominationModel.onBacktoHomeClick}
                        onClosePopup={NominationModel.onClosePopup}
                        showSuccess={NominationModel.showSuccess}
                        confirmationGender={NominationModel.confirmationGender}
                        showGenderConfirmation={
                          NominationModel.showGenderConfirmation
                        }
                        positionName={NominationModel.positionName}
                        setConfirmationGender={
                          NominationModel.setConfirmationGender
                        }
                        setSubmitDisable={NominationModel.setSubmitDisable}
                        submitDisable={NominationModel.submitDisable}
                      />
                    </Col>
                  </Row>
                </div>
              </Form>
            )}
          <NominationSubmission
            nominations={NominationModel.nominations}
            onWidthdarwClick={NominationModel.onWidthdarwClick}
            allowWithdraw={NominationModel.allowWithdraw}
            positions={NominationModel?.positionList}
          />
          <ConfirmPopup
            showPopup={NominationModel.showPopup}
            setShowPopup={NominationModel.setShowPopup}
            onConfirmClick={NominationModel.onConfirmClick}
            message={NominationModel.message}
            isConfirm={NominationModel.isConfirm}
          />
          <Modal className="home-modals" show={NominationModel.showSuccess}>
            <Modal.Header
              className="contnt-center"
              closeButton
              onClick={() => NominationModel.onClosePopup()}
            >
              <div className="check-circle-img">{Icons.successTick}</div>
              <Modal.Title>Your nomination submitted!</Modal.Title>
            </Modal.Header>
            <Modal.Body className="d-flex justify-content-center">
              Election Panel will review your nomination.
            </Modal.Body>
            <Modal.Footer className="d-flex justify-content-center">
              <Button variant="primary" onClick={() => NominationModel.onBacktoHomeClick()}>
                BACK TO HOME
              </Button>
            </Modal.Footer>
          </Modal>
        </Container>
      </section>
    </>
  );
};

export default NominationContainer;
